<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.ADMIN_IPACCESS_LISTS')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
          </template>
          <template v-slot:body>
            <v-alert dense text type="info" v-if="restricted==1">
              {{ $t("SECURITY.RESTRICT_STATUS_ACTIVE") }}
            </v-alert>
            <v-alert dense text type="warning" v-if="restricted==0">
              {{ $t("SECURITY.RESTRICT_STATUS_FREE") }}
            </v-alert>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="ipList"
              item-key="ip"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="deletePref(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <b-row class="my-5">
              <b-col sm="5" id="showTooltip" v-if="restricted==0">
                <b-button 
                  variant="primary" 
                  :disabled="ipList.length == 0"
                  v-b-modal.restrict
                >
                  {{ $t("SECURITY.RESTRICT_BUTTON") }}
                </b-button>
                <b-tooltip target="showTooltip" triggers="hover" :disabled="ipList.length != 0">
                  {{ $t('SECURITY.ADMIN_ACCESS_INFO') }}
                </b-tooltip>
              </b-col>
              <b-col sm="5">
                <b-button 
                  variant="success" 
                  v-if="restricted==1"
                  v-on:click="restrictAdminAccess(0)"
                >
                  {{ $t("SECURITY.ALLOW_BUTTON") }}
                </b-button>
              </b-col>
            </b-row>
            
            <!-- ----------- add preference modal -->
            <b-modal id="addPreference" ref="addPreference" title="New">
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label for="preferenceValue">{{
                      $t("SECURITY.IP")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="preferenceValue"
                      v-model="$v.addForm.value.$model"
                      :state="validateState('value')"
                      :placeholder="$t('SECURITY.IP')"
                      aria-describedby="input-value-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-value-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DESCRIPTION") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-textarea
                      v-model="$v.addForm.desc.$model"
                      :state="validateState('desc')"
                      :placeholder="$t('COMMON.DESCRIPTION')"
                      aria-describedby="input-desc-feedback"
                      rows="4"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-desc-feedback">
                      {{ $t("VALIDATION.MAX_LETTERS", {num:12}) }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="savePreference()">
                  {{ $t("COMMON.ADD") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- delete confirm modal------------- -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DELETE") + ": " + delItem.ip + "?" }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
            <!-- ---------- restrict confirm modal------------- -->
            <b-modal
              id="restrict"
              :title="$t('SECURITY.RESTRICT_BUTTON')"
              ref="restrict-modal"
            >
              <b-alert show variant="danger" v-if="warning!=''">
                {{ $t("SECURITY.YOUR_IP", {ip: yourIp}) }}
              </b-alert>
              <p class="my-1">
                {{ $t("SECURITY.RESTRICT_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('restrict-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="restrictAdminAccess(1)">
                  {{ $t("SECURITY.RESTRICT") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------restrict confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { validationMixin } from "vuelidate";
import { required,maxLength,ipAddress } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "adminPreference",
  components: {
    KTPortlet,
  },
  data: () => ({
    ipList: [],
    yourIp: "",
    warning: "",
    restricted: false,
    addForm: {
      value: null,
      desc: null,
    },
    delItem: "",
    selected:[],
  }),
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("SECURITY.IP"),
          value: "ip",
          sortable: false,
        },
        {
          text: this.$t("COMMON.DESCRIPTION"),
          value: "description",
          sortable: false,
        },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    yourIP: function() {
      return this.yourIp;
    },
    canAdd: function () {
      return this.hasPermission("filtering", 5);
    },
    canRemove: function () {
      return this.hasPermission("filtering", 7);
    },
  },
  validations: {
    addForm: {
      value: {
        required,
        ipAddress,
      },
      desc: {
        maxLength: maxLength(12)
      },
    },
  },
  created() {
    this.getPreferenceReqest();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title:
          this.$t("MENU.SECURITY") +
          " -> " +
          this.$t("MENU.ADMIN_IPACCESS_LISTS"),
      },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs["addPreference"].hide();
      this.resetForm();
    },
    confirmModalClose(modalRef) {
      this.$refs[modalRef].hide();
    },

    /* -----reset form------ */
    resetForm() {
      this.addForm.value = null;
      this.addForm.desc = null;
    },

    /* ---------read item---------- */
    getPreferenceReqest() {
      this.ipList = [];
      postRequest({
        action: "getAdminRestrictedIps",
        token: localStorage.id_token,
      }).then((res) => {
        if (res) {
          if (res.returncode) {
            if (res.returndata != null) {
              this.ipList = res.returndata.ips;
              this.yourIp = res.returndata.current_ip;
              this.warning = res.returndata.warning;
              this.restricted = res.returndata.allow_only_restricted;
            }
          } else {
            this.ipList = [];
            this.makeToastVariant("danger", res.returndata);
          }
        }
      });
    },

    /* --------- add preference -------------- */
    openCreateModal() {
      this.$refs["addPreference"].show();
    },
    savePreference() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.value.$anyError) {
        return;
      }
      postRequest({
        action: "addAdminRestrictedIp",
        token: localStorage.id_token,
        ip: this.addForm.value,
        description: this.addForm.desc,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getPreferenceReqest();
          this.modalClose();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* -------delete item----- */
    deletePref(item) {
      this.delItem = item;
    },
    deleteRequest(ip) {
      postRequest({
        action: "removeAdminRestrictedIp",
        token: localStorage.id_token,
        ip: ip,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getPreferenceReqest();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.confirmModalClose("delConfirm-modal");
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.ip);
    },
    restrictAdminAccess(onlyRestricted) {
      if (onlyRestricted == 1 && this.ipList.length == 0){
        this.makeToastVariant("danger", this.$t("SECURITY.ADMIN_ACCESS_INFO"));
        return;
      }
      postRequest({
        action: "setAdminRestrictedIps",
        token: localStorage.id_token,
        allow_only_restricted: onlyRestricted,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getPreferenceReqest();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.confirmModalClose("restrict-modal");
      });
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
